










































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue{


    open_medium(){
        window.open('https://medium.com/@leo.dorn', '_blank')
    }
}
